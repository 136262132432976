/*
 * @Author: ken yang
 * @Date: 2023-08-28 09:49:58
 * @LastEditTime: 2023-09-11 15:04:49
 * @LastEditors: ken yang
 * @Description:
 * @FilePath: /uexch_pc/src/utils/url.js
 */

if (process.env.NODE_ENV === 'production') {
  const VITE_APP_DOMAIN = window.location.hostname
  import.meta.env.VITE_APP_BASE_URL = `https://${VITE_APP_DOMAIN}/api`
  import.meta.env.VITE_APP_WS_URL = `wss://${VITE_APP_DOMAIN}/ws/zh_cn/`
  import.meta.env.VITE_APP_WS_FUTURES_URL = `wss://${VITE_APP_DOMAIN}/zh_cn/`
  import.meta.env.VITE_APP_H5_URL = `https://${VITE_APP_DOMAIN}`
}

const wsUrl = import.meta.env.VITE_APP_WS_URL
const wsFuturesUrl = import.meta.env.VITE_APP_WS_FUTURES_URL

const serveUrl = import.meta.env.VITE_APP_BASE_URL
const h5Url = import.meta.env.VITE_APP_H5_URL

const bftLink = 'https://etherscan.io/token/0xedbf98724a86f92baefac101082c366e96f1e9d9'

export {
  wsUrl,
  wsFuturesUrl,
  serveUrl,
  h5Url,
  bftLink,
}
